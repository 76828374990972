import { AlertPreset, AlertStyle } from "@frec-js/common-web";
import { UilExclamationCircle } from "@iconscout/react-unicons";
import { UilInfoCircle } from "@iconscout/react-unicons";
import {
  Alert as Component,
  AlertProps as ComponentProps,
  CloseButton,
} from "@mantine/core";
import { forwardRef, useCallback, useState } from "react";

import { mergeClassNames } from "../../utils/classNames";

const AlertPresetMap: Record<
  AlertPreset,
  {
    icon: React.ReactNode;
    classNames: Record<string, string>;
  }
> = {
  [AlertPreset.Default]: {
    icon: <UilInfoCircle />,
    classNames: AlertStyle.DefaultStyles,
  },
  [AlertPreset.Warning]: {
    icon: <UilExclamationCircle width={20} height={20} />,
    classNames: AlertStyle.WarningStyles,
  },
  [AlertPreset.Error]: {
    icon: <UilExclamationCircle width={20} height={20} />,
    classNames: AlertStyle.ErrorStyles,
  },
  [AlertPreset.InlineError]: {
    icon: <UilExclamationCircle width={20} height={20} />,
    classNames: AlertStyle.InlineErrorStyles,
  },
};

interface AlertProps extends ComponentProps {
  preset?: AlertPreset;
}

export const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  { children, classNames, icon, preset = AlertPreset.Default, ...props },
  ref,
) {
  const presets = AlertPresetMap[preset];
  // We have two close buttons because one is top-aligned and the other is center-aligned
  // When there is a title, show the built-in one
  // When there is no title, show the custom one
  const showComponentCloseButton = Boolean(
    props.withCloseButton && props.title,
  );
  const showCustomCloseButton = Boolean(props.withCloseButton && !props.title);
  const [closed, setClosed] = useState(false);
  const handleClose = useCallback(() => {
    setClosed(true);
  }, []);
  return closed ? null : (
    <Component
      ref={ref}
      variant={"outline"}
      icon={icon ?? presets.icon ?? <UilInfoCircle />}
      classNames={mergeClassNames(
        presets.classNames,
        classNames as Record<string, string>,
      )}
      onClose={handleClose}
      closeButtonLabel="Close"
      {...props}
      withCloseButton={showComponentCloseButton}
    >
      {showCustomCloseButton && (
        <CloseButton
          className="float-right w-8 px-2"
          onClick={handleClose}
          variant="transparent"
          size={20}
          iconSize={20}
          aria-label="Close"
        />
      )}
      {children}
    </Component>
  );
});
